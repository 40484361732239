// import messages from '@/langs';

export default defineI18nConfig(() => {
    return {
        locale: 'en',
        // messages,
        defaultLocale: 'en',
        availableLocales: ['en'],
        fallbackLocale: 'en',
    };
});
